import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PrismicMenuItem } from "../../../types/app-types";
import { ContentService } from '../../services/content/content.service';
import { ProfileStorageService } from '../../services/profile/profile-storage.service';
import { AccountResource } from '../../services/resources/account.resources';
import { UtilService } from '../../services/util/util.service';

const content = require('./navigation.content.json');

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnDestroy {
  public showNavigation: boolean = false;
  public activeRoute: string;
  public subscriptions: Subscription[] = [];
  public screenMode: string;
  public profileSubTitle: string;
  public authenticateButtonContentKey: string = 'navigation.sign-out-action';
  public isMobile: boolean = false;
  public isLoggedIn: boolean = false;
  public cmsContent: MenuItem[] = [];

  @ViewChild('navigation', { read: ElementRef })
  public navigation: ElementRef;

  @Input()
  public isMinimized: boolean;

  @Output()
  public minificationMenuEvent: EventEmitter<void> = new EventEmitter<void>();

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private utilService: UtilService,
    private profileStorageService: ProfileStorageService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private contentService: ContentService,
    private accountService: AccountResource,
  ) {
    contentService.loadContent(content);




    this.cmsContent = [
        {
          "type": "Dashboard",
          "visibility": "LoggedIn",
          "label": "Mijn Zorginspirator",
          "link": null,
          "target_blank": false
        },
        {
          "type": "Functies",
          "visibility": "Always",
          "label": "Functies",
          "link": null,
          "target_blank": false
        },
        {
          "type": "Opleidingen",
          "visibility": "Always",
          "label": "Opleidingen",
          "link": null,
          "target_blank": false
        },
        {
          "type": "Mijn gegevens",
          "visibility": "NotLoggedIn",
          "label": "Mijn zoekprofiel",
          "link": null,
          "target_blank": false
        },
        {
          "type": "Internal",
          "visibility": "Always",
          "label": "Doe een test",
          "link": "test-overzicht",
          "target_blank": false
        },
        {
          "type": "External",
          "visibility": "Always",
          "label": "Loopbaan in de zorg",
          "link": "https://welkom.zorginspirator.nl/loopbaan",
          "target_blank": false
        },
        {
          "type": "External",
          "visibility": "Always",
          "label": "Voor leidinggevenden",
          "link": "https://welkom.zorginspirator.nl/leidinggevenden",
          "target_blank": false
        },
        {
          "type": "Internal",
          "visibility": "Always",
          "label": "Over Zorginspirator",
          "link": "/over-zorginspirator",
          "target_blank": false
        },
      ].map(item => new MenuItem(item).updateVisibility(this.isLoggedIn));
    this.changeStateOnIsLoggedIn(this.profileStorageService.getIsLoggedIn());
    this.subscriptions.push(this.profileStorageService.loggedInSubject.subscribe(this.changeStateOnIsLoggedIn));

    this.subscriptions.push(
      utilService.getScreenMode$().subscribe((screenMode: any) => {
        this.screenMode = screenMode;
        this.isMobile = screenMode.includes('MOBILE');
      })
    );

    this.subscriptions.push(
      router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          this.activeRoute = e.url;
          this.showNavigation = e.url !== '/';
        }
      })
    );

    document.addEventListener('click', this.closeIfOutsideOfNavigation);

    // close navigation on esc key
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.minimizeMenu();
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    document.removeEventListener('click', this.closeIfOutsideOfNavigation);
  }

  public minimizeMenu(): void {
    this.minificationMenuEvent.emit();
  }

  public handleAuthentication(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.minimizeMenu();

    if (this.profileStorageService.getIsLoggedIn()) {
      this.logout();
    } else {
      this.openLoginModal();
    }
  }

  public async openLoginModal(): Promise<void> {
    const { LoginModalComponent } = await import('../account/login-modal/login-modal.component');

    const config: MatDialogConfig = this.isMobile
      ? {
        height: '100%',
        width: '100%',
        maxHeight: '100%',
        maxWidth: '100%'
      }
      : {
        width: '380px',
        maxWidth: '100%'
      };

    this.matDialog.open(LoginModalComponent, config);
  }

  public logout(): void {
    this.profileStorageService.deleteProfileStorage();
    this.router.navigate(['/']);
    this.accountService
      .getLogout()
      .subscribe(() =>
        this.matSnackBar.open(this.contentService.getContent('navigate.signed-out-message'), '', { duration: 2000 })
      );
  }

  public changeStateOnIsLoggedIn = (isLoggedIn: boolean): void => {
    this.isLoggedIn = isLoggedIn;
    this.cmsContent = this.cmsContent.map(item => item.updateVisibility(isLoggedIn))
    this.profileSubTitle = isLoggedIn ? 'navigation.signed-in' : 'navigation.not-signed-in';
    this.authenticateButtonContentKey = isLoggedIn ? 'navigation.sign-out-action' : 'navigation.sign-in-action';
  };

  public closeIfOutsideOfNavigation = (event: MouseEvent): void => {
    if (this.navigationIsOpen() && this.outsideNavigation(event)) {
      this.minimizeMenu();
    }
  };

  private navigationIsOpen: Function = (): boolean => {
    const dimensions = this.navigation.nativeElement.getBoundingClientRect();
    return dimensions.width > 300;
  };

  private outsideNavigation: Function = (event: MouseEvent): boolean => {
    const dimensions = this.navigation.nativeElement.getBoundingClientRect();

    return event.clientY < dimensions.top || event.clientX > dimensions.right;
  };
}

export class MenuItem {
  type: string
  label: string
  targetBlank: boolean
  visible: boolean
  private readonly _link: string
  private readonly visibility: string

  public constructor(item: PrismicMenuItem) {
    this.type = item.type
    this.label = item.label
    this._link = item.link
    this.targetBlank = item.target_blank
    this.visibility = item.visibility
  }

  public updateVisibility(isLoggedIn: boolean): MenuItem {
    this.visible = this.visibility === 'Always' ||
      (isLoggedIn && this.visibility === 'LoggedIn') ||
      (!isLoggedIn && this.visibility === 'NotLoggedIn')
    return this;
  }

  public isActiveRoute(activeRoute: string): boolean {
    return activeRoute === this.link || activeRoute.includes(this.link)
  }

  public get icon() {
    switch (this.type) {
      case 'Dashboard':
        return { type: 'svg', name: '/assets/icons/z_leeg.svg' }
      case 'Functies':
        return { type: 'svg', name: '/assets/icons/functies.svg' }
      case 'Opleidingen':
        return { type: 'svg', name: '/assets/icons/opleidingen.svg' }
      case 'Mijn gegevens':
        return null;
      default:
        return null;
    }
  }

  public get link() {
    if (this._link) {
      return this._link;
    }

    switch (this.type) {
      case 'Dashboard':
        return '/dashboard';
      case 'Functies':
        return '/functies';
      case 'Opleidingen':
        return '/opleidingen';
      case 'Mijn gegevens':
        return '/profiel';
      case 'TestOverzicht':
        return '/test-overzicht';
      default:
        return null;
    }
  }
}
