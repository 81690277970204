<div class="footer" *ngIf="!hideFooter">
  <div>
    <div>
      <a routerLink="/">
        <img class="footer__logo" src="assets/logo.svg" alt="zorginspirator" />
      </a>
    </div>
    <div class="footer__text">
      <p>Zorginspirator is een onafhankelijk platform, mogelijk gemaakt door zes arbeidsmarktfondsen in de zorg (AO
        VVT, AZN, OOfGGZ, SoFoKleS - Kamer UMC, StAG en StAZ) en door het Ministerie van Volksgezondheid, Welzijn en
        Sport (VWS).
      </p>
    </div>
  </div>
  <div>
    <h4 class="footer__title">Menu</h4>
    <ul class="footer__menu">
      <li class="footer__menu_link">
        <a data-cy="footerLink" routerLink="/functies">Functies</a>
      </li>
      <li class="footer__menu_link">
        <a data-cy="footerLink" routerLink="/opleidingen">Opleidingen</a>
      </li>
      <li class="footer__menu_link">
        <a data-cy="footerLink" routerLink="/profiel">Mijn zoekprofiel</a>
      </li>
      <li class="footer__menu_link">
        <a data-cy="footerLink" routerLink="/test-overzicht">Doe een test</a>
      </li>
      <li class="footer__menu_link">
        <a data-cy="footerLink" href="https://welkom.zorginspirator.nl/loopbaan">Loopbaan in de zorg</a>
      </li>
      <li class="footer__menu_link">
        <a data-cy="footerLink" href="https://welkom.zorginspirator.nl/leidinggevenden">Voor leidinggevenden</a>
      </li>
    </ul>
  </div>
  <div>
    <h4 class="footer__title">Over</h4>
    <ul class="footer__about">
      <li class="footer__about_link">
        <a data-cy="footerLink" routerLink="/over-zorginspirator">Over Zorginspirator</a>
      </li>
      <li class="footer__about_link">
        <a data-cy="footerLink" routerLink="/info/downloads">Downloads</a>
      </li>
      <li class="footer__about_link">
        <a data-cy="footerLink" routerLink="/info/zorgwebwijzer">Webwijzer</a>
      </li>
      <li class="footer__about_link">
        <a data-cy="footerLink" routerLink="/info/cookies-en-privacy">Cookies en privacy</a>
      </li>
    </ul>
  </div>
  <div>
    <h4 class="footer__title">
      Heb je een vraag?
    </h4>
    <div class="footer__contact">
      <p>
        Bel dan met de helpdesk op <a data-cy="footerLink" class="footer__contact_link" href="tel:0031 30 266 9499">030
          266 9499</a></p>
      <p>
        of mail naar <a data-cy="footerLink" class="footer__contact_link"
          href="mailto:support@zorginspirator.nl">support@zorginspirator.nl</a>
      </p>
    </div>
  </div>
  <div class="social">
    <a target="_blank" rel="noopener noreferrer" data-cy="footerLink" href="https://www.instagram.com/zorginspirator/" class="social__link">
      <svg width="39" height="39" viewBox="0 0 39 39" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.16 12.88C26.97 12.39 26.74 12.05 26.38 11.68C26.02 11.31 25.67 11.09 25.18 10.9C24.81 10.76 24.26 10.59 23.24 10.54C22.14 10.49 21.81 10.48 19.02 10.48C16.23 10.48 15.9 10.49 14.8 10.54C13.78 10.59 13.23 10.76 12.86 10.9C12.37 11.09 12.03 11.32 11.66 11.68C11.3 12.05 11.07 12.39 10.88 12.88C10.74 13.25 10.57 13.8 10.52 14.82C10.47 15.92 10.46 16.25 10.46 19.04C10.46 21.83 10.47 22.16 10.52 23.26C10.57 24.28 10.74 24.83 10.88 25.2C11.07 25.69 11.3 26.03 11.66 26.4C12.03 26.76 12.37 26.99 12.86 27.18C13.23 27.32 13.78 27.49 14.8 27.54C15.9 27.59 16.23 27.6 19.02 27.6C21.81 27.6 22.14 27.59 23.24 27.54C24.26 27.49 24.81 27.32 25.18 27.18C25.67 26.99 26.01 26.76 26.38 26.4C26.75 26.03 26.97 25.69 27.16 25.2C27.3 24.83 27.47 24.28 27.52 23.26C27.57 22.16 27.58 21.83 27.58 19.04C27.58 16.25 27.57 15.92 27.52 14.82C27.47 13.8 27.3 13.25 27.16 12.88ZM19.03 24.39C16.07 24.39 13.67 21.99 13.67 19.03C13.67 16.07 16.07 13.67 19.03 13.67C21.99 13.67 24.39 16.07 24.39 19.03C24.39 21.99 21.99 24.39 19.03 24.39ZM24.6 14.71C23.91 14.71 23.35 14.15 23.35 13.46C23.35 12.77 23.91 12.21 24.6 12.21C25.29 12.21 25.85 12.77 25.85 13.46C25.85 14.15 25.29 14.71 24.6 14.71Z" />
        <path
          d="M19.03 15.5498C17.11 15.5498 15.55 17.1098 15.55 19.0298C15.55 20.9498 17.11 22.5098 19.03 22.5098C20.95 22.5098 22.51 20.9498 22.51 19.0298C22.51 17.1098 20.95 15.5498 19.03 15.5498Z" />
        <path
          d="M19.03 0C8.52 0 0 8.52 0 19.03C0 29.54 8.52 38.06 19.03 38.06C29.54 38.06 38.06 29.54 38.06 19.03C38.06 8.52 29.54 0 19.03 0ZM29.4 23.33C29.35 24.44 29.17 25.2 28.91 25.86C28.64 26.55 28.29 27.13 27.71 27.71C27.13 28.29 26.55 28.65 25.86 28.91C25.2 29.17 24.44 29.34 23.33 29.39C22.22 29.44 21.86 29.45 19.03 29.45C16.2 29.45 15.84 29.44 14.73 29.39C13.62 29.34 12.86 29.16 12.2 28.91C11.51 28.64 10.93 28.29 10.35 27.71C9.77 27.13 9.41 26.55 9.15 25.86C8.89 25.2 8.72 24.44 8.67 23.33C8.62 22.22 8.61 21.86 8.61 19.03C8.61 16.2 8.62 15.84 8.67 14.73C8.72 13.62 8.9 12.86 9.15 12.2C9.42 11.51 9.77 10.93 10.35 10.35C10.93 9.77 11.51 9.41 12.2 9.15C12.86 8.89 13.62 8.72 14.73 8.66C15.84 8.61 16.2 8.6 19.03 8.6C21.86 8.6 22.22 8.61 23.33 8.66C24.44 8.71 25.2 8.89 25.86 9.15C26.55 9.42 27.13 9.77 27.71 10.35C28.29 10.93 28.65 11.51 28.91 12.2C29.17 12.86 29.34 13.62 29.4 14.73C29.45 15.84 29.46 16.2 29.46 19.03C29.46 21.86 29.45 22.22 29.4 23.33Z" />
      </svg>
    </a>

    <a target="_blank" rel="noopener noreferrer" data-cy="footerLink" href="https://www.facebook.com/zorginspiratorNL/" class="social__link">
      <svg width="39" height="39" viewBox="0 0 39 39" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.77 0C9.25998 0 0.73999 8.52 0.73999 19.03C0.73999 29.54 9.25998 38.06 19.77 38.06C30.28 38.06 38.8 29.54 38.8 19.03C38.8 8.52 30.28 0 19.77 0ZM25.07 11.56H22.56C21.67 11.56 21.49 11.92 21.49 12.84V15.05H25.07L24.73 18.93H21.5V30.53H16.87V18.98H14.46V15.05H16.87V11.95C16.87 9.04 18.42 7.53 21.87 7.53H25.08V11.56H25.07Z" />
      </svg>
    </a>

    <a target="_blank" rel="noopener noreferrer" data-cy="footerLink" href="https://www.linkedin.com/company/zorginspirator/" class="social__link">
      <svg width="39" height="39" viewBox="0 0 39 39" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.5 0C8.98997 0 0.469971 8.52 0.469971 19.03C0.469971 29.54 8.98997 38.06 19.5 38.06C30.01 38.06 38.53 29.54 38.53 19.03C38.53 8.52 30.01 0 19.5 0ZM14.81 27.51H10.87V14.79H14.81V27.51ZM12.82 13.12C11.53 13.12 10.49 12.07 10.49 10.77C10.49 9.47 11.53 8.42 12.82 8.42C14.11 8.42 15.15 9.47 15.15 10.77C15.15 12.07 14.11 13.12 12.82 13.12ZM29.57 27.51H25.65V20.83C25.65 19 24.95 17.98 23.51 17.98C21.93 17.98 21.11 19.05 21.11 20.83V27.51H17.33V14.79H21.11V16.5C21.11 16.5 22.25 14.4 24.94 14.4C27.63 14.4 29.57 16.05 29.57 19.46V27.52V27.51Z" />
      </svg>
    </a>
  </div>
</div>