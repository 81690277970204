import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc: any
  ) {
  }

  public setPageTitle(title: string): void {
    if (title) {
      this.title.setTitle(title);
    } else {
      this.title.setTitle('Zorginspirator | Werken en leren in de zorg');
    }
  }

  public setDescription(description: string): void {
    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
    } else {
      this.meta.removeTag('name=\'description\'');
      this.meta.updateTag({
        name: 'description',
        content: 'Zorginspirator, de onafhankelijke site voor jouw toekomst in de zorg. ✔ Ontdek 400+ functies ✔ Alle zorgopleidingen ✔ Tips voor jouw volgende stap'
      });
    }
  }

  public setKeywords(keywords: string): void {
    if (keywords) {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    } else {
      this.meta.removeTag('name=\'keywords\'');
      this.meta.updateTag({
        name: 'keywords',
        content: 'zorg, inspirator, zorginspirator, zorgsector, toekomst, functie, functies, opleiding, opleidingen, vacature, werkperspectief'
      });
    }
  }

  public createLinkForCanonicalURL(url?: string): void {
    const canURL = url === undefined ? this.doc.URL : url;
    const canonical: any = [...this.doc.head.children].find(child => {
      return child.getAttribute('rel') === 'canonical';
    });
    canonical.setAttribute('href', canURL);
  }
}
