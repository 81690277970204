<div class="header-horizontal" [ngClass]="{
    minimized: showNavigation || direction === 'down' || isSmallerMobile
  }" [ngStyle]="{
    'max-width.px': isChatFlow
      ? null
      : contentMaxWidth
  }">
  <div class="header-horizontal__flex-wrapper">
    <div class="header-horizontal__hamburger menu-icon-wrapper" (click)="handleHamburgerClick()">
      <div class="menu-icon" [ngClass]="{ 'menu-icon--open': !isMinimized }">
        <span class="menu-icon__item"></span>
        <span class="menu-icon__item"></span>
        <span class="menu-icon__item"></span>
        <span class="menu-icon__item"></span>
      </div>
    </div>

    <a routerLink="/">
      <img [ngClass]="{
          'header-horizontal__logo--minimized':
            showNavigation || direction === 'down' || isSmallerMobile
        }" class="header-horizontal__logo" src="assets/logo.svg" alt="zorg inspirator" />
    </a>

    <nav class="header-horizontal__flex-wrapper header-spacer">
      <a data-cy="headerLink" routerLink="/functies" [ngClass]="{ 'active': isActiveRoute('/functie') }">
        Functies
      </a>
      
      <a data-cy="headerLink" routerLink="/opleidingen" [ngClass]="{ 'active': isActiveRoute('/opleiding') }">
        Opleidingen
      </a>
      
      <!-- <a routerLink="/test-overzicht" [ngClass]="{ 'active': isActiveRoute(['/landing-test', '/test']) }">
        Testen
      </a> -->
      
      <a data-cy="headerLink" href="https://welkom.zorginspirator.nl/leidinggevenden">
        Voor leidinggevenden
      </a>
    
      <a data-cy="headerLink" *ngIf="!isLoggedIn" routerLink="/profiel" [ngClass]="{ 'active': isActiveRoute('/profiel') }">
        Mijn zoekprofiel
      </a>
    
      <a data-cy="headerLink" *ngIf="isLoggedIn" routerLink="/dashboard" [ngClass]="{ 'active': isActiveRoute('/dashboard') }">
        Mijn dashboard
      </a>
    </nav>

    <button *ngIf="isChatFlow" (click)="goBack()" class="header-horizontal__chatter-icon" data-cy="chatbotSluiten">
      <mat-icon class="material-icons header-horizontal__chatter-close">
        close
      </mat-icon>
    </button>

    <div *ngIf="!isChatFlow" class="header-horizontal__login-logout">
      <app-button class="header-horizontal__login-logout--button" mode="secondary" [fullWidth]="false" [rounded]="true"
      (clicked)="handleAuthentication()">
      <mat-icon>input</mat-icon>
      <span>{{
        isLoggedIn
        ? ("header.menu-sign-out" | content)
        : ("header.menu-sign-in" | content)
      }}</span>
    </app-button>
    </div>
  </div>
</div>